@import url('https://fonts.googleapis.com/css2?family=Monda&family=Offside&display=swap');


 :root {
   --primaryColor: #BFC9D0;
   --secondaryColor: #E4636B;
   --bodyColor: #707070;
   --backgroundPrimaryColor: #F0F6FA;

   --primaryFont : 'Monda', sans-serif;
   --secondaryFont : 'Offside', sans-serif;
 }
body {
  margin: 0;
  font-family: var(--primaryFont);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color : var(--bodyColor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
