.noMargin {
    margin: 0;
}

.primaryColor {
    color: var(--primaryColor);
}
.secondaryColor {
    color: var(--secondaryColor);
}
.backgroundPrimaryColor {
    background-color: var(--backgroundPrimaryColor);
}
.secondaryFont {
    font-family: var(--secondaryFont);
}

.secondaryTitle {
    font-family: var(--secondaryFont);
    color: var(--bodyColor);
    font-size: 1.5em;
    font-weight: normal;
    text-transform: uppercase;
}

.goToBack {
    z-index: -1;
}
.goToFront {
    z-index: 2;
}